@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Comic+Neue&display=swap");

* {
  font-family: "Comic Neue", cursive;
}

body {
  background-image: url("./assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100vh;
}

.swiper-button-prev,
.swiper-button-next {
  color: #2eb6ea !important;
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.fade-up {
  animation: fade-up 0.5s ease-out forwards;
}

.coupon-bg {
  background-image: url("./assets/couponbg.avif");
  background-repeat: no-repeat;
  background-size: cover;
}

.arrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 15px);
  width: 30px;
  height: 30px;
  cursor: 'pointer';
  background-color: rgba(255, 255, 255, 0.8);
  color: #333;
  font-size: 2em;
  text-align: center;
  line-height: 30px;
  border-radius: 50%;
}

@media (max-width: 640px) {
  .arrow {
    width: 20px;
    height: 20px;
    font-size: 1.5em;
    top: calc(50% - 10px);
  }
}

.slide-left {
  animation: slide-left 0.5s forwards;
}

.slide-right {
  animation: slide-right 0.5s forwards;
}

@keyframes slide-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

